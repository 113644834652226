<!--
 * @Descripttion:
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-06 11:31:38
-->
<template>
	<div>
		<van-nav-bar title="添加评审专业" left-arrow @click-left="$router.push({name : fromPage,query:{fromPage:'selectmajors'}})" />
		<van-search v-model="searchV" placeholder="请输入搜索关键词" show-action shape="round">
			<template #action>
				<p class="fc">搜索</p>
			</template>
		</van-search>

		<div class="flex-around whiteBg mar-t2">
			<h3 :class="{f5:true,title : true,'active-title': activeTitle ==0}" @click="activeTitle = 0">工程类</h3>
			<h3 :class="{f5:true,title : true,'active-title': activeTitle ==1}" @click="activeTitle = 1">货物类</h3>
			<h3 :class="{f5:true,title : true,'active-title': activeTitle ==2}" @click="activeTitle = 2">服务类</h3>
		</div>
		<div class="w-line mar-t2"></div>
		<div>
			<ul>
				<li v-for="(item,index) in list" :key="index">
					<div class="flex-item" @click="item.click = !item.click">
						<van-icon :color="item.checked ? '#00CCB2' : '#D9D9D9'" :name="item.checked ? 'checked':'circle'" @click.stop="selectedParent(item)"
						 size="0.5rem" />
						<h3 class="mar-l2">{{ item.name }}</h3>
						<van-icon name="play" :class="{arrow:true,'arrow-up':item.click}" />
					</div>
					<ul v-show="item.click">
						<li v-for="(child,i) in item.children" :key="child.id" class="flex-item son" @click="selectedSon(item,child)">
							<van-icon :color="child.checked ? '#00CCB2' : '#D9D9D9'" :name="child.checked ? 'checked':'circle'" size="0.5rem" />
							<h3 class="mar-l2">{{ showMajorAlias(child) }}</h3>
						</li>
					</ul>
				</li>
			</ul>
			<div class="h2"></div>
		</div>

		<div class="fixed-bottom whiteBg pad-row" style="padding-bottom:0.3rem">
			<button class="btn w-100" @click="$router.push({name : fromPage,query:{fromPage:'selectmajors'}})">保存</button>
		</div>
	</div>
</template>

<script>
	import {
		Search,
		Icon
	} from 'vant'
	export default {
		data() {
			return {
				searchV: '',
				activeTitle: 0,
				list: [],
				gongchengList: [],
				huowuList: [],
				fuwuList: [],
				queryParam: {
					orgId: "",
					expertId: ''
				},
				pageParams: {
					pageNo: 1,
					pageSize: 20
				},
				orgExtrMajors: [],
				orgExtrMajorsMap: new Map(),
				//返回路由
				fromPage: 'step1',
				workweixin: false,				//企业微信
				openUserid:	"",
			}
		},

		components: {
			[Icon.name]: Icon,
			[Search.name]: Search
		},

		watch: {
			activeTitle(newV) {
				if (newV == 0) this.list = this.gongchengList;
				else if (newV == 1) this.list = this.huowuList;
				else if (newV == 2) this.list = this.fuwuList;
			}
		},
		// beforeRouteEnter(to, from, next) {
		// 	next(vm => {
		// 		//项目由第一步进入-正常流程
		// 		if(from.name == "step1"){
		// 			// alert(JSON.stringify(vm.orgExtrMajors))
		// 			vm.fallbackUrl='step1'
		// 		}
		// 		//专业选择
		// 		else if(from.name=='step3'){
		// 			vm.fallbackUrl='step3'
		// 		}
		// 		//场景复现
		// 		else{
		// 			vm.fallbackUrl='step1'
		// 		}
		// 	})
		// },
		created() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//接收路由参数
			if (this.$route.query.fromPage) {
				this.fromPage = this.$route.query.fromPage
			}
			//
			if (this.$ls.get("edCurrentOrg")) {
				this.queryParam.orgId = this.$ls.get("edCurrentOrg").id;
			}
			if (this.$ls.get("orgExtrMajors")) {
				this.orgExtrMajors = this.$ls.get("orgExtrMajors");
				if (this.orgExtrMajors.length > 0) {
					for (let i = 0; i < this.orgExtrMajors.length; i++) {
						this.orgExtrMajorsMap.set(this.orgExtrMajors[i].id, this.orgExtrMajors[i].id);
					}
				}
			}
			//获取树结构
			this.getTreeData();
		},
		methods: {

			/**
			 * @Description: 一级专业的全选与取消
			 * @Author: Niklaus
			 * @Date: 2021-01-06 15:18:40
			 * @param {*} item
			 */
			selectedParent(item) {
				item.checked = !item.checked
				for (let i = 0; i < item.children.length; i++) {
					const ele = item.children[i].checked = item.checked;
				}
				this.getExpertOpenMajors();
			},

			/**
			 * @Description: 二级专业的 选中与取消
			 * @Author: Niklaus
			 * @Date: 2021-01-06 15:08:51
			 * @param {*} item ： 一级专业
			 * @param {*} child： 二级专业
			 */
			selectedSon(item, child) {
				child.checked = !child.checked
				let hasChecked = false
				for (let i = 0; i < item.children.length; i++) {
					const ele = item.children[i];
					if (ele.checked) {
						hasChecked = true
						break
					}
				}
				item.checked = hasChecked;
				this.getExpertOpenMajors();
			},
			//获取专业树结构
			getTreeData() {
				var params = Object.assign({}, this.queryParam)
				params.pageNo = this.pageParams.pageNo;
				params.pageSize = this.pageParams.pageSize;
				params.openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				//专家抽取页面跳转过来-
				//mapDelFlag 0此标志查询机构有效的专业， 无参数查询机构下所有的专业
				if(this.fromPage=="step1"){
					params.mapDelFlag = '0'     //有效
				}else if(this.fromPage=="step3"){
					params.mapDelFlag = '0'     //有效
				}
				let accesstoken = this.$ls.get("token")
				this.$ajax({
					methods: 'get',
					url: '/gateway/ed/wx/wxEdReviewMajorTwoThreeClassController/voByOrgExpertList/',
					headers: {
						'X-Access-Token': this.$ls.get("token")
					},
					params: params,
				}).then(res => {
					if (res.data.success) {
						this.list = this.cleanSeletedData(res.data.result.gongchengList);
						this.gongchengList = this.cleanSeletedData(res.data.result.gongchengList);
						this.huowuList = this.cleanSeletedData(res.data.result.huowuList);
						this.fuwuList = this.cleanSeletedData(res.data.result.fuwuList);
						// this.list = this.showSelectedData(res.data.result.gongchengList);
						// this.gongchengList = this.showSelectedData(res.data.result.gongchengList);
						// this.huowuList = this.showSelectedData(res.data.result.huowuList);
						// this.fuwuList = this.showSelectedData(res.data.result.fuwuList);
						//取消选中
						this.uncheckedAllMajors()
					} else {
						this.$toast.fail("失败")
						console.log(res.data.message)
					}
				});
			},
			//遍历专业树结构，获取选中的数据
			getExpertOpenMajors() {
				let openMajors = this.orgExtrMajors;
				if (this.gongchengList.length > 0) {
					for (let i = 0; i < this.gongchengList.length; i++) {
						let obj = this.gongchengList[i];
						if (obj.children.length > 0) {
							for (let j = 0; j < obj.children.length; j++) {
								let childObj = obj.children[j];
								if (childObj.checked) {
									if (this.orgExtrMajorsMap.get(childObj.id) == undefined) {
										this.orgExtrMajorsMap.set(childObj.id, childObj.id);
										openMajors.push(childObj);
									};
								} else {
									this.orgExtrMajorsMap.delete(childObj.id);
								}
							}
						}
					}
				};
				if (this.huowuList.length > 0) {
					for (let i = 0; i < this.huowuList.length; i++) {
						let obj = this.huowuList[i];
						if (obj.children.length > 0) {
							for (let j = 0; j < obj.children.length; j++) {
								let childObj = obj.children[j];
								if (childObj.checked) {
									if (this.orgExtrMajorsMap.get(childObj.id) == undefined) {
										this.orgExtrMajorsMap.set(childObj.id, childObj.id);
										openMajors.push(childObj);
									};
								} else {
									this.orgExtrMajorsMap.delete(childObj.id);
								}
							}
						}
					}
				};
				if (this.fuwuList.length > 0) {
					for (let i = 0; i < this.fuwuList.length; i++) {
						let obj = this.fuwuList[i];
						if (obj.children.length > 0) {
							for (let j = 0; j < obj.children.length; j++) {
								let childObj = obj.children[j];
								if (childObj.checked) {
									if (this.orgExtrMajorsMap.get(childObj.id) == undefined) {
										this.orgExtrMajorsMap.set(childObj.id, childObj.id);
										openMajors.push(childObj);
									};
								} else {
									this.orgExtrMajorsMap.delete(childObj.id);
								}
							}
						}
					}
				};
				let newOpenMajors = [];
				for (let i = 0; i < openMajors.length; i++) {
					if (this.orgExtrMajorsMap.has(openMajors[i].id)) {
						newOpenMajors.push(openMajors[i]);
					}
				}
				this.$ls.set("orgExtrMajors", newOpenMajors, 60 * 60 * 1000);
				this.orgExtrMajors = newOpenMajors;
				console.log(this.orgExtrMajors);
			},
			//遍历专业数据 清除选中的数据
			cleanSeletedData(listData) {
				if (listData == '' || listData == undefined || listData.length == 0) {
					return [];
				}
				if (listData.length > 0) {
					for (let i = 0; i < listData.length; i++) {
						let obj = listData[i];
						if (obj.children.length > 0) {
							let newChildren = [];
							for (let j = 0; j < obj.children.length; j++) {
								let childObj = obj.children[j];
								if (this.orgExtrMajorsMap.get(childObj.id) == undefined) {
									newChildren.push(childObj);
								}
							}
							obj.children = newChildren;
						}
						listData[i] = obj;
					}
				};
				return listData;
			},
			//遍历专业数据  显示已经选中的数据
			showSelectedData(listData) {
				if (listData == '' || listData == undefined || listData.length == 0) {
					return [];
				}
				if (listData.length > 0) {
					for (let i = 0; i < listData.length; i++) {
						let obj = listData[i];
						if (obj.children.length > 0) {
							let newChildren = [];
							for (let j = 0; j < obj.children.length; j++) {
								let childObj = obj.children[j];
								if (this.orgExtrMajorsMap.get(childObj.id) == undefined) {
									childObj.checked = false;
								} else {
									childObj.checked = true;
									obj.checked = true;
								}
								newChildren.push(childObj);
							}
							obj.children = newChildren;
						}
						listData[i] = obj;
					}
				};
				return listData;
			},
			//开始下一个方法
			searchByMajorName() {
				console.log(12131);
			},
			//优先显示备注
			showMajorAlias(item) {
				if (item.majorAlias != null && item.majorAlias != '') {
					return item.majorAlias
				} else {
					return item.majorName
				}
			},
			//下一个方法
			uncheckedAllMajors:function(){
				//将选中的项去掉选中标志
				for(let i=0;i<this.list.length;i++){
				  if(this.list[i].checked){
					this.list[i].checked = false
				  }
				  //子节点
				  if(this.list[i].children){
					for(let j=0;j<this.list[i].children.length;j++){
					  this.list[i].children[j].checked = false
					}
				  }
				}
				for(let i=0;i<this.gongchengList.length;i++){
				  if(this.gongchengList[i].checked){
					this.gongchengList[i].checked = false
				  }
				  //子节点
				  if(this.gongchengList[i].children){
					for(let j=0;j<this.gongchengList[i].children.length;j++){
					  this.gongchengList[i].children[j].checked = false
					}
				  }
				}
				for(let i=0;i<this.huowuList.length;i++){
				  if(this.huowuList[i].checked){
					this.huowuList[i].checked = false
				  }
				  //子节点
				  if(this.huowuList[i].children){
					for(let j=0;j<this.huowuList[i].children.length;j++){
					  this.huowuList[i].children[j].checked = false
					}
				  }
				}
				for(let i=0;i<this.fuwuList.length;i++){
				  if(this.fuwuList[i].checked){
					this.fuwuList[i].checked = false
				  }
				  //子节点
				  if(this.fuwuList[i].children){
					for(let j=0;j<this.fuwuList[i].children.length;j++){
					  this.fuwuList[i].children[j].checked = false
					}
				  }
				}
			}
		}

	}
</script>

<style scoped>
	.flex-item {
		margin-top: 0.2rem;
		position: relative;
		padding: 0.1rem 0.5rem
	}
</style>
